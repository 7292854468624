import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";

export const VerifyForm = () => {
    const [certificateId, setCertificateId] = useState("");
    const [name, setName] = useState("");
    const [verifyingEntity, setVerifyingEntity] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [verificationResult, setVerificationResult] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("https://backend.ornatemaritime.net/" + '/api/verification',{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    name: name,
                    verifying_entity: verifyingEntity,
                    organization_name: organizationName,
                    certificate_id: certificateId,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setVerificationResult(data.result);
            } else {
                setVerificationResult("NOT_FOUND");
            }
        } catch (error) {
            setVerificationResult("NOT_FOUND");
        }

        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setVerificationResult(null);
    };

    return (
        <div className="container">
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        maxLength="50"
                        required
                        placeholder="Enter name"
                    />
                </Form.Group>

                <Form.Group controlId="formVerifyingEntity">
                    <Form.Label>Verifying Entity</Form.Label>
                    <Form.Control
                        as="select"
                        value={verifyingEntity}
                        onChange={(e) => setVerifyingEntity(e.target.value)}
                        required
                    >
                        <option value="">Select an entity</option>
                        <option value="Individual">Individual</option>
                        <option value="Organization">Organization</option>
                    </Form.Control>
                </Form.Group>

                {verifyingEntity === "Organization" && (
                    <Form.Group controlId="formOrganizationName">
                        <Form.Label>Organization Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={organizationName}
                            onChange={(e) => setOrganizationName(e.target.value)}
                            maxLength="100"
                            placeholder="Enter organization name"
                        />
                    </Form.Group>
                )}

                <Form.Group controlId="formCertificateId">
                    <Form.Label>Certificate Number</Form.Label>
                    <Form.Control
                        type="text"
                        value={certificateId}
                        onChange={(e) => setCertificateId(e.target.value)}
                        maxLength="255"
                        required
                        placeholder="Enter certificate number"
                    />
                </Form.Group>

                <Button className="w-100" variant="info" type="submit">
                    Verify
                </Button>
            </Form>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Verification Result</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {verificationResult === "VALID" ? (
                        <div style={{ color: "green", fontWeight: "bold" }}>
                            This certificate is VALID.
                        </div>
                    ) : (
                        <div style={{ color: "red", fontWeight: "bold" }}>
                            This certificate is NOT FOUND.
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
